<template>
	<!-- 企业用户绑卡 -->
	<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
		<div class="form-Box">
			<div class="title flex-row-align-center text-center">
				<i class="iconfont icon-biaodan font-size30"></i>
				<div class="font-size16">{{title}}</div>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="145px" class="demo-ruleForm">
				<el-form-item label="统一社会信用代码">
					<el-input v-model="userinfo.fIdentityCode" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item v-if="!changeAccount" label="开户行" prop="fBankTypeID">
					<el-select v-model="ruleForm.fBankTypeID" placeholder="请选择开户行" @change="selectChange">
						<el-option v-for="(item,index) in bankList" :key="index" :label="item.fBankTypeName" :value="item.fBankTypeID"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-else label="开户行">
					<el-select v-model="ruleForm.fBankTypeID" :disabled="changeAccount" placeholder="请选择开户行" @change="selectChange">
						<el-option v-for="(item,index) in bankList" :key="index" :label="item.fBankTypeName" :value="item.fBankTypeID"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="支行信息" prop="fBranchID" style="line-height: none;">
					<el-select filterable :filter-method="dataFilter" v-model="ruleForm.fBranchID" placeholder="请选择支行信息">
						<el-option v-for="(item,index) in fkhmC1List" :key="index" :label="item.fkhmC1" :value="item.fqhhO2"></el-option>
					</el-select>
					<div style="color: #ff9900;font-size: 14px;line-height: 10px;">
						支行信息选择错误会影响提现，请仔细核对。
					</div>
				</el-form-item> -->
				<el-form-item label="支行信息" prop="fBranchName" style="line-height: none;position: relative;">
					<el-input placeholder="请选择支行信息" disabled :value="ruleForm.fBranchName">
					</el-input>
					<div class="button-Branch background-color-theme pointer" @click="ToBranchSelection">请选择...</div>
					<div style="color: #ff9900;font-size: 14px;line-height: 10px;">
						支行信息选择错误会影响提现，请仔细核对。
					</div>
				</el-form-item>
				<el-form-item v-if="!changeAccount" label="银行账户" prop="fBankAccounts">
					<el-input v-model="ruleForm.fBankAccounts" type="number" :maxlength="32" :minlength="12"></el-input>
				</el-form-item>
				<el-form-item v-else label="银行账户">
					<el-input v-model="ruleForm.fBankAccounts" type="number" :disabled="changeAccount" :maxlength="32" :minlength="12"></el-input>
				</el-form-item>
				<el-form-item v-if="!changeAccount" label="法人姓名" prop="fLegalPerson">
					<el-input v-model="ruleForm.fLegalPerson"></el-input>
				</el-form-item>
				<el-form-item v-if="!changeAccount" label="法人身份证号" prop="fLegalPersonCertificateNumber">
					<el-input v-model="ruleForm.fLegalPersonCertificateNumber"></el-input>
				</el-form-item>
				<el-form-item v-if="!changeAccount" label="法人手机号" prop="fLegalPersonPhone">
					<el-input v-model="ruleForm.fLegalPersonPhone"></el-input>
				</el-form-item>
				<el-form-item label="" id="setform" prop="radio">
					<div style="line-height: 20px !important;">
						<el-checkbox-group v-model="ruleForm.radio">
							<el-checkbox label="我已阅读并同意"></el-checkbox>
						</el-checkbox-group>
						<span v-for="(item,index) in zftAgreement" :key='index' style="display: inline-block;line-height: 20px;">
							<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
						</span>
					</div>
				</el-form-item>
				<el-form-item>
					<el-button v-if="changeAccount" class="background-color-theme" :loading="subStatue" @click="ConfirmChange('ruleForm')">确认变更</el-button>
					<el-button v-else class="background-color-theme" :loading="subStatue" @click="submitForm('ruleForm')">申请开通</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
		<el-dialog title="激活提现卡" :visible.sync="dialogFormVisible">
			<el-form :model="dialogruleForm" :rules="dialogrules" ref="dialogruleForm">
				<el-form-item label="激活金额" :label-width="formLabelWidth" prop="money">
					<el-input v-model="dialogruleForm.money" autocomplete="off" :required="true" placeholder="请输入来账金额激活"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="validateCancel('dialogruleForm')">取 消</el-button>
				<el-button type="primary" :loading="validStatue" @click="validateConfirm('dialogruleForm')">确 定</el-button>
			</div>
		</el-dialog>
		<agreementDialog :iContents='iContent' ref="areDialog"></agreementDialog>
		<BranchSelection v-if="BranchSelection" :showModel='BranchSelection' :id="fBankCode" @change="branchChange"></BranchSelection>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import IdentityCodeValid from '@/utils/checkIdent'
	import agreementDialog from '@/components/agreementDialog';
	import BranchSelection from './BranchSelection';
	export default {
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error("金额必须为数字值且最多保留两位小数"));
				} else {
					callback();
				}
			};
			//   身份证号码验证规则
			let checkRegNo = (rule, value, callback) => {
				if (!IdentityCodeValid(value)) {
					callback(new Error('请输入正确的身份证'))
				} else {
					callback()
				}
			}
			// 验证手机号的校验规则
			let checkMobile = (rule, value, callback) => {
				// 验证手机号的正则表达式
				const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
				if (value == '') {
					callback(new Error("手机号码不能为空"));
				} else if (regMobile.test(value)) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("请输入正确的手机号"))
			}

			return {
				validStatue: false,
				subStatue: false,
				BranchSelection: false,
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: "80px",
				dialogruleForm: {
					money: null,
				},
				dialogrules: {
					money: [{
						validator: checkMoney,
						trigger: "blur"
					}],
				},
				loading: true,
				title: '',
				type: '',
				fAccount: '',
				changeAccount: false,
				userinfo: {},
				bankList: [], //开户行
				certificateType: [],
				verificationCodeText: "获取验证码",
				ss: "",
				fLegalPerson: '',
				fBankCode: '',
				ruleForm: {
					radio: false,
					fIdentityCode: '',
					fLegalPerson: '',
					fCertificateTypeCode: '',
					fLegalPersonCertificateNumber: '',
					fLegalPersonPhone: '',
					fBranchName: '',
					fBranchID: '',
					fBankTypeID: '',
				},
				rules: {
					fBankAccounts: [{
						required: true,
						message: "请输入银行账户",
						trigger: "blur",
					}],
					fLegalPerson: [{
						required: true,
						message: "请输入法人姓名",
						trigger: "blur",
					}],
					fBankTypeID: [{
						required: true,
						message: "请选择开户行",
						trigger: "change",
					}],
					fBranchName: [{
						required: true,
						message: '请选择支行信息',
						trigger: 'change'
					}],
					fLegalPersonCertificateNumber: [{
							required: true,
							message: '请输入正确的身份证',
							trigger: 'blur'
						},
						{
							validator: checkRegNo,
							trigger: 'blur'
						}
					],
					fLegalPersonPhone: [{
							required: true,
							message: "请输入正确的手机号",
							trigger: "blur",
						},
						{
							validator: checkMobile,
							trigger: "blur"
						}
					],
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意开通账服通协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				},
				obj: null,
				noticeShow: false,
				iContent: '',
				fkhmC1List: [],
				fkhmC1ListCopy: [],
				fBranchID: '',
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(["getUserInfo"]),
		},
		components: {
			agreementDialog,
			BranchSelection
		},
		created() {
			// this.ruleForm = {}
			this.title = this.$route.query.title;
			this.type = this.$route.query.type;
			this.getInfo();
			if (this.type == 3 && this.title == '提现账户信息变更') {
				this.fAccount = this.$route.query.fAccount;
				this.changeAccount = true;
			}
		},
		mounted() {
			this.getReadAgreement();
		},
		methods: {
			//根据输入的字段
			dataFilter(val) {
				if (this.ruleForm.fBranchID) {
					this.ruleForm.fBranchID = '';
				}
				if (val) {
					let fBankTypeCode = this.bankList.find(p => p.fBankTypeID == this.ruleForm.fBankTypeID);
					let parms = {
						"fBankTypeCode": fBankTypeCode.fBankTypeCode,
						"filter": val
					};
					this.ApiRequestPostNOMess('api/mall/ebbase/bank-type-detail/get-list-drop-down-box', parms).then(
						(result) => {
							this.fkhmC1List = result.obj
						},
						(rej) => {}
					);
				} else {
					this.fkhmC1List = this.fkhmC1ListCopy
				}

			},
			//选择支行信息
			selectChange(e) {
				// if (this.ruleForm.fBranchID) {
				// 	this.ruleForm.fBranchID = '';
				// }
				let fBankTypeCode = this.bankList.find(p => p.fBankTypeID == this.ruleForm.fBankTypeID);
				this.fBankCode = fBankTypeCode.fBankTypeCode;
				// let parms = {
				// 	"fBankTypeCode": fBankTypeCode.fBankTypeCode,
				// 	"filter": ""
				// };
				// this.ApiRequestPostNOMess('api/mall/ebbase/bank-type-detail/get-list-drop-down-box', parms).then(
				// 	(result) => {
				// 		console.log(result, '支行');
				// 		this.fkhmC1List = result.obj
				// 		this.fkhmC1ListCopy = result.obj
				// 	},
				// 	(rej) => {}
				// );
			},

			//获取账服通协议列表
			getReadAgreement() {
				this.ApiRequestPostNOMess('api/mall/ebbase/agreement-history/get-by-asc-list', {}).then(
					(result) => {
						if (result.obj) {
							this.zftAgreement = result.obj.items
						}
					},
					(rej) => {}
				);
			},
			closeNoticeShows() {
				this.noticeShow = false;
			},
			shows() {
				this.noticeShow = true;
			},
			showAgreeDia(a) {

				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			//   获取用户信息
			getInfo() {
				this.ApiRequestPost(
					"/api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full", {}
				).then(
					(result) => {
						console.log(result, "00000000000");
						this.userinfo = result.obj
						this.getBankList();
					},
					(rej) => {
						this.getBankList();
					}
				);
			},
			//   获取开户行下拉框数据
			getBankList() {
				this.ApiRequestPost('/api/mall/ebbase/bank-type/get-acs-banktype', {}).then(
					(result) => {
						this.bankList = result.obj.items;
						console.log(this.bankList, "开户行");
						this.getCertificateType();
						if (this.type == 3 && this.title == '提现账户信息变更') {
							this.getChangeData();
						} else {
							this.loading = false
						}
					},
					(rej) => {
						this.getCertificateType();
						this.loading = false
					}
				);
			},
			//   获取账服通证件类型
			getCertificateType() {
				this.ApiRequestPost(
					"/api/mall/ebbase/certificate-type-external-system/get-acs-list", {}
				).then(
					(result) => {
						console.log(result, "00000000000");
						this.certificateType = result.obj.items
					},
					(rej) => {}
				);
			},
			submitForm(formName) {
				console.log(this.ruleForm.fBranchID, 'this.ruleForm.fBranchIDthis.ruleForm.fBranchID');
				this.obj = {};
				console.log(this.form, "formform");
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.subStatue = true;
						this.ApiRequestPostNOMess(
							"/api/mall/ebcustomer/bank-account/create-acs-b2b-account", {
								// buisCreditCode: this.userinfo.fIdentityCode,
								fLegalPerson: this.ruleForm.fLegalPerson,
								// fCertificateTypeCode:'-1',
								fLegalPersonCertificateNumber: this.ruleForm.fLegalPersonCertificateNumber,
								fLegalPersonPhone: this.ruleForm.fLegalPersonPhone,
								fBankTypeID: parseInt(this.ruleForm.fBankTypeID),
								fBranchID: this.ruleForm.fBranchID,
								fBankAccount: this.ruleForm.fBankAccounts
							}
						).then(
							(result) => {
								console.log(result, 55555555555555);
								if (result.code == "HengHeCode1000") {
									this.obj = result.obj;
									this.$message({
										message: result.message,
										type: 'success'
									});
									// this.$router.push({ path: "/businessme/AccountPay" });
									this.dialogFormVisible = true
									this.subStatue = false;
								} else {
									this.subStatue = false;
									this.$message({
										message: result.message,
										type: 'error'
									});
								}
							},
							(rej) => {
								this.subStatue = false;
							}
						);
					} else {
						this.subStatue = false;
						return false;
					}
				});
			},
			// 验证弹框点击确定
			validateConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.validStatue = true;
						this.ApiRequestPostNOMess(
							"/api/mall/ebcustomer/bank-account/validatie-acs-b2b-account", {
								fMoney: Number(this.dialogruleForm.money),
								fAccountID: this.obj.fAccountID
							}
						).then(
							(result) => {
								if (result.code == "HengHeCode1000") {
									this.$router.push({
										path: "/businessme/AccountPay"
									});
								} else {
									this.$message({
										message: result.message,
										type: 'error'
									});
									this.dialogFormVisible = true
									this.validStatue = false;
								}

							},
							(rej) => {
								this.validStatue = false;
							}
						);
					} else {
						this.validStatue = false;
						return false;
					}
				});
			},
			// 验证弹框点击取消
			validateCancel(formName) {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
				this.$router.push({
					path: "/businessme/AccountPay"
				});
			},
			//选择支行弹窗
			ToBranchSelection() {
				if (this.ruleForm.fBankTypeID == '' || this.ruleForm.fBankTypeID == undefined || this.ruleForm.fBankTypeID == null) {
					this.$message.info('请先选择开户行!')
					return
				} else {
					this.BranchSelection = true;
				}
			},
			//选择支行回传数据
			branchChange(e, data) {
				this.BranchSelection = e;
				if (data.isChange) {
					this.ruleForm.fBranchName = data.data[0].fkhmC1
					this.ruleForm.fBranchID = data.data[0].fqhhO2
				}
			},
			//获取变更前的数据
			getChangeData() {
				this.ApiRequestPostNOMess(
					"/api/mall/ebcustomer/bank-account/get", {
						id: this.fAccount
					}
				).then(
					(result) => {
						console.log(result, "变更数据");
						this.ruleForm.fBankTypeID = result.obj.fBankID + '';
						this.ruleForm.fBankAccounts = result.obj.fAccountNumber;
						this.ruleForm.fBranchName = result.obj.fBranchName;
						this.ruleForm.fBranchID = result.obj.fBranchID;
						this.fBankCode = result.obj.fBankCode;
						this.loading = false
					},
					(rej) => {
						this.loading = false
					}
				);
			},
			//确认变更
			ConfirmChange(formName) {
				console.log(this.ruleForm, 'this.ruleForm.fBranchID');
				this.obj = {
					fAccountID: this.fAccount,
				};
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.subStatue = true;
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/update-acs-b2b-withdraw-account", {
								fAccountID: this.fAccount,
								fBranchID: this.ruleForm.fBranchID,
							}
						).then(
							(result) => {
								console.log(result, "确认变更");
								if (result.code == "HengHeCode1000") {
									this.$message({
										message: result.message,
										type: 'success'
									});
									this.subStatue = false;
									setTimeout(() => {
										this.$router.go(-1)
									}, 1000);
								} else {
									this.subStatue = false;
								}
							},
							(rej) => {
								this.subStatue = false;
							}
						);
					} else {
						this.subStatue = false;
						return false;
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.button-Branch {
		position: absolute;
		z-index: 2;
		top: 5px;
		left: 310px;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	::v-deep .el-dialog {
		width: 500px;
	}

	//协议复选框样式
	::v-deep .el-checkbox-group {
		width: fit-content !important;
		display: inline !important;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 380px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 55px auto 16px;
		margin-left: 384px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		// width: 462px;
		margin: 0px auto;
		margin-left: 180px;
		position: relative;
		z-index: 1;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}
</style>
